import React from "react";
import Swiper from "react-id-swiper";
import "swiper/swiper.min.css";
import event1 from "../assets/images/aashayein.jpg";  
import event2 from "../assets/images/prabhat.jpg";  
import event3 from "../assets/images/prayas.jpg";
import event4 from "../assets/images/samarpan.jpg";  
import event5 from "../assets/images/navratri.jpg";

const Events = () => {
  const params = {
    slidesPerView: 3,
    loop: false,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 2
      },
      320: {
        slidesPerView: 1
      }
    }
  };
  return (
    <div>
      {/* <section className="course-one__top-title home-one">
        <div className="container">
          <div className="block-title mb-0">
            <h2 className="block-title__title">
              Explore our <br />
              popular courses
            </h2>
          </div>
        </div>
        <div className="course-one__top-title__curve"></div>
      </section> */}

      <section className="course-one course-one__teacher-details home-one">
        <div className="container">
          <div className="course-one__carousel">
            <Swiper {...params}>
              <div className="item">
                <div className="course-one__single color-1">
                  <div className="course-one__image">
                    <img src={event1} alt="" />
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                    Aashayein - The Annual Function
                    </a>
                    {/* <div className="course-one__admin">
                      <img src={team1} alt="" />
                      by <a href="/teacher-details">Lou Guerrero</a>
                    </div> */}
                    {/* <h2 className="course-one__title">
                      <a href="/course-details">Aashayein - The Annual Function</a>
                    </h2> */}
                    
                    <a href="#none" className="course-one__link">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="course-one__single color-2">
                  <div className="course-one__image">
                    <img src={event2} alt="" />
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                    Prabhat - The Knowledge Fair
                    </a>
                    
                    {/* <h2 className="course-one__title">
                      <a href="/course-details">Prabhat - The Knowledge Fair</a>
                    </h2> */}
                
                    <a href="#none" className="course-one__link">
                     Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="course-one__single color-3">
                  <div className="course-one__image">
                    <img src={event3} alt="" />
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                     Prayas - Joy Of Giving
                    </a>
                    {/* <h2 className="course-one__title">
                      <a href="/course-details">Prayas - Joy Of Giving</a>
                    </h2> */}
                    
                    <a href="#none" className="course-one__link">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="course-one__single color-4">
                  <div className="course-one__image">
                    <img src={event4} alt="" />
                    <i className="far fa-heart"></i>
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                    Samarpan - Grans Parents Day
                    </a>
                   
                    {/* <h2 className="course-one__title">
                      <a href="/course-details">Samarpan - Grans Parents Day</a>
                    </h2> */}
                   
                    <a href="#none" className="course-one__link">
                    Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="course-one__single color-5">
                  <div className="course-one__image">
                    <img src={event5} alt="" />
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                    Navratri
                    </a>
                    {/* <h2 className="course-one__title">
                      <a href="/course-details">Navratri</a>
                    </h2> */}
                  
                    <a href="#none" className="course-one__link">
                    Read More
                    </a>
                  </div>
                </div>
              </div>
              
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Events;
